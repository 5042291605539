import { screenSizes, spacingPx } from '@shipt/design-system-themes'
import styled from 'styled-components'

/**
 * @deprecated Use the GridLayoutContainer
 */
export const LayoutContainer = styled.div<{ enableXLarge?: boolean }>`
  margin-left: 0;
  margin-right: 0;

  @media ${screenSizes.tablet} {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  @media ${screenSizes.smDesktop} {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ enableXLarge }) =>
    enableXLarge &&
    `
    @media ${screenSizes.smDesktop} {
      max-width: none;
      margin-left: 2.25rem;
      margin-right: 2.25rem;
    }

    @media ${screenSizes.lgDesktop} {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }
  `};
`

export const GridLayoutContainer = styled.div<{
  contentMaxWidth?: boolean
}>`
  --padding-inline: ${spacingPx('lg')};
  --content-max-width: 1080px;
  --shelf-outset: 4px;

  &,
  & > .fullWidth {
    display: grid;
    grid-template-columns:
      [full-width-start] minmax(
        calc(var(--padding-inline) - var(--shelf-outset)),
        1fr
      )
      [shelf-outset-start] var(--shelf-outset)
      [content-start] min(
        100% - (var(--padding-inline) * 2),
        var(--content-max-width)
      )
      [content-end] var(--shelf-outset) [shelf-outset-end] minmax(
        calc(var(--padding-inline) - var(--shelf-outset)),
        1fr
      )
      [full-width-end];
  }

  & > .content,
  & > .fullWidth > * {
    grid-column: content;
  }

  & > .fullWidth {
    grid-column: full-width;
  }

  & > .shelfOutset {
    grid-column: shelf-outset;
  }

  @media ${screenSizes.tablet} {
    --padding-inline: ${spacingPx('xxl')};
  }

  @media ${screenSizes.smDesktop} {
    --padding-inline: ${spacingPx('3xl')};
  }

  ${({ contentMaxWidth }) =>
    contentMaxWidth &&
    `
      @media ${screenSizes.tablet} {
        --content-max-width: 1400px;
      }
    `}
`
