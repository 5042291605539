import { routes } from '@shared/constants/routes'
import { getUtmParams } from '@/utils/url'
import { parseUrl, stringify } from '@/utils/queryString'
import { useHydrated } from '@/components/ClientOnly'

export const useUtmUrl = (pathname: string) => {
  const hydrated = useHydrated()
  if (!hydrated) return pathname

  const { baseUrl, fragment, queryString } = splitUrl(pathname)
  const utmUrl = buildUtmUrl(
    `${baseUrl}${queryString ? '?' + queryString : ''}`
  )
  return `${utmUrl}${fragment ? '#' + fragment : ''}`
}

const buildUtmUrl = (pathname: string) => {
  const parsedUrl = parseUrl(pathname)
  const queryParams = getUtmParams(pathname.includes(routes.SIGN_UP.url))
  const query = stringify({ ...queryParams, ...parsedUrl.query })
  return query.length ? `${parsedUrl.url}?${query}` : pathname
}

const splitUrl = (pathname: string) => {
  const [baseUrl, fragmentAndAfter] = pathname.split('#')
  const [fragment, queryString] = fragmentAndAfter?.split('?') || []
  return { baseUrl, fragment, queryString }
}
