import { Row } from '@shipt/design-system-layouts'
import { HeaderLogo } from '@/components/HeaderLogo'
import { NextLink } from '@/elements/Link'
import { useHomeUrl } from '@/hooks/useHomeUrl'

export const HeaderLogoLink = () => {
  const homeUrl = useHomeUrl()

  return (
    <NextLink href={homeUrl}>
      <Row>
        <HeaderLogo />
      </Row>
    </NextLink>
  )
}
