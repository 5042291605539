import styled from 'styled-components'
import { LogoIcon } from '@shipt/design-system-icons'

export const HeaderLogo = () => {
  return <Logo aria-label="Shipt logo" />
}

const Logo = styled(LogoIcon)`
  max-width: 6.25rem;
  height: 2.25rem;
  color: ${({ theme }) => theme.plum};
`
