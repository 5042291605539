import { useUserId } from '@/services/User/hooks'
import { routes } from '@shared/constants/routes'

/**
 * Returns the appropriate home URL depending on if the user is logged in or not.
 */
export const useHomeUrl = () => {
  const userId = useUserId()
  return userId ? routes.RETAILER_HOMEPAGE.url : routes.UNGATED_HOMEPAGE.url
}
